
import { Component, Vue } from "vue-property-decorator";
import FormCard from "@/components/FormCard.vue";
import LogoSingle from "@/components/global/LogoSingle.vue";
import RegisterForm from "@/components/forms/RegisterForm.vue";

@Component({
  components: {
    FormCard,
    LogoSingle,
    RegisterForm
  }
})
export default class Register extends Vue {
  private showError($event: string) {
    this.$q.notify({
      message: ("<div style='font-size:20px'>" +
        this.$t("error." + $event) +
        "</div>") as string,
      color: "accent",
      html: true,
      position: "top",
      timeout: 5000
    });
  }
}
